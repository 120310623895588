import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'

import "@aws-amplify/ui-vue";

import Amplify from 'aws-amplify';

let siteURL = process.env.NODE_ENV === 'development' ? `http://localhost:8080/` : `https://lpgalmanac.com/`

Amplify.configure({
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'us-west-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-west-2_JuToZLUuu',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '5bn7n5sfltiuo091783m65amah',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        authenticationFlowType: 'USER_PASSWORD_AUTH',

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'sulpetro.auth.us-west-2.amazoncognito.com',
            scope: ['email', 'openid'],
            redirectSignIn: siteURL,
            redirectSignOut: siteURL,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
