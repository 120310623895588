<template>
  <div id="legend" class="cont">
    <div class="container-inner">
      <v-card class="c">
        <v-list>
          <template>
            <div>
              <div :key="colour.name" class="tile" v-for="colour in colours">
                <div
                  class="colour"
                  :style="{ background: colour.colour }"
                ></div>
                <div>{{ colour.name }}</div>
              </div>
            </div>
          </template>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "maplegend",
  data() {
    return {
      colours: [
        { name: "Gas Processing", colour: "#0A82AD" },
        { name: "Gas Processing + Frac.", colour: "#dd9e21" },
        { name: "Fractionation", colour: "#27CE2D" },
        // {name: 'Fractionation/Terminal', colour: '#e55e5e'},
        // {name: 'Fractionation/Terminal/Storage', colour: '#01579B'},
        { name: "Storage", colour: "#9E20AC" },
        { name: "Terminal", colour: "#E51405" },
        // {name: 'Terminal/Storage', colour: '#3bb2d0'},
        { name: "Refinery", colour: "#DC418F" },
      ],
    };
  },
};
</script>

<style scoped>
.c {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.colour {
  border: 0.3px solid rgba(0, 0, 0, 0.1);
  height: 20px;
  margin-right: 10px;
  min-width: 20px;
  width: 20px;
}
.cont {
  border: 1.5px rgba(0, 0, 0, 0.1) solid;
  border-radius: 50;
  bottom: 120px;
  right: 32px;
  width: 220px;
  padding: 0;
  position: fixed;
  transform-origin: bottom;
  z-index: 9;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.description-style {
  align-self: center;
  display: flex;
  font-size: 10px;
  max-width: 80%;
  padding-top: 10px;
  text-align: center;
}
.icon {
  padding-bottom: 1.5px;
}
.tile {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0px;
  padding: 3px 0;
  white-space: nowrap;
  width: 80%;
}
.title-outer {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
  width: 100%;
  wrap: nowrap;
}
.title-style {
  margin-right: 5px;
}
</style>
