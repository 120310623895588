/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import map from '@/components/map'
// import PaymentRedirect from '@/components/PaymentRedirect';

Vue.use(Router)

function requireAuth(to, from, next) {

  if (!localStorage.getItem('user')) {
    next()
      next({
        path: '/'
      });
  } else {
    next();
  }
}

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'map',
      component: map,
      beforeEnter: requireAuth
    },
    {
      path: '/login'
    }
    // {
    //   path: '/payment-redirect', component: PaymentRedirect
    // },
  ]
})
