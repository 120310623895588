<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  data () {
  return {
    options: {
      maintainAspectRatio: false,
      /**
       * If responsive is set to true by default, charts rendered on
       * a different tab or off screen will not display due to a lack
       * of height given by vue-chartjs.
       */
      responsive: false
    }
  }
},
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  mounted () {
    // this.options.responsive = true
    this.renderChart(this.chartData, this.options)
  }
}
</script>
