<template>
  <div
    v-bind:class="{ panelShow: showPanel, panelHide: !showPanel }"
    ref="sidepanel"
  >
    <v-card dense>
      <v-toolbar color="cyan darken-2" dark dense class="bar">
        <v-spacer></v-spacer>
        <v-btn
          class="login-btn"
          text
          color="white"
          @click="logout"
          v-if="checkUser()"
        >
          <v-icon class="login-icon">mdi-logout-variant</v-icon>
          Logout
        </v-btn>
        <v-btn
          class="login-btn"
          text
          color="white"
          v-if="!checkUser()"
          @click="login"
        >
          <v-icon class="login-icon">mdi-login</v-icon>
          Login
        </v-btn>
      </v-toolbar>
      <v-tabs
        background-color="grey darken-3"
        dark
        v-model="tab"
        align-with-title
      >
        <v-tabs-slider color="cyan darken-2"></v-tabs-slider>
        <v-tab
          :disabled="
            (tabItems.indexOf(item) !== 0 && !checkUser()) ||
              (tabItems.indexOf(item) === 1 && !plantDetails())
          "
          v-for="item in tabItems"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="0">
          <v-card class="card">
            <v-container>
              <v-card-text>
                <!-- <v-btn class="login-btn" text color="primary">
                         <router-link class="link" to="/terms-and-conditions">
                           <v-icon class="icon">mdi-credit-card-outline</v-icon>SUBSCRIBE
                         </router-link>
                       </v-btn> -->
                <!-- </div> -->
                <div class="overline">
                  Powered by Sulpetro
                </div>
                <br />
                <v-divider></v-divider>
                <br />
                <br />
                <div>
                  <div><img :src="logo" class="logo" height="150" /></div>
                  The LPG Almanac is a compilation of statistics, up-to-date
                  facts and figures, on everything you need to know about
                  natural gas liquids (NGL) product facilities in North America.
                  An ongoing production supply statistical study providing a
                  data sheet on every refinery, plant, fractionator and
                  storage/terminal in the US and Canada handling or producing
                  natural gas liquids.
                </div>
                <br />
                <br />
                <v-divider></v-divider>
                <br />
                <br />
                <div class="my-3 subtitle-2">
                  Web-Based Application Functionality
                </div>
                <ul>
                  <li>
                    GIS map with every facility denoted on a push-pin basin map
                    with satellite imagery.
                  </li>
                  <li>
                    Filtering by basin, state, county, organization, capacity.
                  </li>
                  <li>
                    8 year historical production profile charts included in base
                    price (up to 25 years available).
                  </li>
                  <li>Unlimited .csv downloads.</li>
                  <li>Encrypted and secure access.</li>
                  <li>
                    Customizable API support for corporate license holders.
                  </li>
                </ul>
                <div class="my-3 subtitle-2">
                  Key Data Points
                </div>
                <ul>
                  <li>
                    For gas processing plants and oil refineries the datasheet
                    provides four years' historical gas throughput for each
                    plant and NGL/LPG production volumes.
                  </li>
                  <li>
                    Each datasheet describes the facility in terms of history,
                    processes, capacity, location, logistics (including
                    pipeline, rail and truck connections), ownership and contact
                    details.
                  </li>
                </ul>
                <div class="my-3 subtitle-2">
                  Specification Products
                </div>
                <ul>
                  <li>Natural Gas throughput</li>
                  <li>C2- Ethane</li>
                  <li>C3-Propane</li>
                  <li>iC4-Isobutane</li>
                  <li>nC4-Normal butane</li>
                  <li>NG-natural gasoline</li>
                </ul>
                <br />
                <div>
                  The LPG Almanac is dynamic and versioned using a revision
                  service. The publication is sold on base price plus ongoing
                  annual subscription basis.
                </div>
                <br />
                <v-divider></v-divider>
                <br />
                <div>
                  <b
                    >For technical support or questions, call toll free at 1
                    (833) 574-3282.</b
                  >
                </div>
                <br />
              </v-card-text>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item key="1">
          <v-card class="card">
            <v-container v-if="plantDetails()">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    {{
                      plantDetails() &&
                        plantDetails().plants_owners &&
                        plantDetails().plants_owners[0].organization_name
                    }}
                  </div>
                  <v-list-item-title class="headline mb-1">
                    <div class="plant-name-outer">
                      <div class="plant-name">
                        {{ plantDetails().plant_name }}
                      </div>
                      <DownloadButton
                        :selectedFeature="[plantDetails().pbf_id]"
                      />
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ plantDetails().city ? plantDetails().city + ", " : ""
                    }}{{
                      plantDetails().county
                        ? plantDetails().county + " County, "
                        : ""
                    }}{{ plantDetails().state }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <div
                  class="my-3 subtitle-2"
                  v-if="plantDetails() && plantDetails().plants_types"
                >
                  <span
                    :key="type.name"
                    v-for="(type, i) in plantDetails().plants_types"
                    >{{
                      i === plantDetails().plants_types.length - 1
                        ? type.name
                        : type.name + ", "
                    }}</span
                  >
                  <span
                    v-if="
                      plantDetails().process &&
                        plantDetails().process.length > 0
                    "
                    >({{ plantDetails().process }})</span
                  >
                </div>
                <br />
                <div>{{ plantDetails().description }}</div>
                <br />
                <br />
                <v-expansion-panels multiple accordion flat :value="expanded">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      <b>Production Metrics & Details</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <template v-slot:default>
                          <v-data-table
                            :headers="setTableHeaders()"
                            :items="setTableItems()"
                            dense
                            hide-default-footer
                            class="elevation-1"
                          ></v-data-table>
                        </template>
                      </v-simple-table>
                      <br />
                      <p>Production Notes</p>
                      <div>{{ plantDetails().production_notes }}</div>
                      <br />
                      <p>Logistics</p>
                      <div>{{ plantDetails().pipeline_details }}</div>
                      <br />
                      <v-divider></v-divider>
                      <br />
                      <div class="icons">
                        <b>Methods of Transport</b>
                        <div class="icons-inner">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="icon"
                                v-on="on"
                                v-bind="attrs"
                                medium
                                :color="
                                  plantDetails().pipeline_access === true
                                    ? 'blue'
                                    : 'rgba(0,0,0,0.2)'
                                "
                                >mdi-pipe</v-icon
                              >
                            </template>
                            <span>Pipeline</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="icon"
                                v-on="on"
                                v-bind="attrs"
                                medium
                                :color="
                                  plantDetails().rail_access === true
                                    ? 'blue'
                                    : 'rgba(0,0,0,0.2)'
                                "
                                >mdi-train</v-icon
                              >
                            </template>
                            <span>Rail</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="icon"
                                v-on="on"
                                v-bind="attrs"
                                medium
                                :color="
                                  plantDetails().truck_access === true
                                    ? 'blue'
                                    : 'rgba(0,0,0,0.2)'
                                "
                                >mdi-truck</v-icon
                              >
                            </template>
                            <span>Truck</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="icon"
                                v-on="on"
                                v-bind="attrs"
                                medium
                                :color="
                                  plantDetails().water_access === true
                                    ? 'blue'
                                    : 'rgba(0,0,0,0.2)'
                                "
                                >mdi-waves</v-icon
                              >
                            </template>
                            <span>Water</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <br />
                      <v-divider></v-divider>
                      <br />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="hasStorageData()">
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      <b>Storage Capacity</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table v-if="hasStorageData()">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Type
                              </th>
                              <th class="text-left">
                                Capacity (MMcfd)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="plantDetails().storage_ngl_mix">
                              <td><b>Natural Gas Liquids</b></td>
                              <td>
                                {{
                                  formatNumber(plantDetails().storage_ngl_mix)
                                }}
                              </td>
                            </tr>
                            <tr v-if="plantDetails().storage_ethane">
                              <td><b>Ethane</b></td>
                              <td>
                                {{
                                  formatNumber(plantDetails().storage_ethane)
                                }}
                              </td>
                            </tr>
                            <tr v-if="plantDetails().storage_propane">
                              <td><b>Propane</b></td>
                              <td>
                                {{
                                  formatNumber(plantDetails().storage_propane)
                                }}
                              </td>
                            </tr>
                            <tr v-if="plantDetails().storage_iso_butane">
                              <td><b>ISO Butane</b></td>
                              <td>
                                {{
                                  formatNumber(
                                    plantDetails().storage_iso_butane
                                  )
                                }}
                              </td>
                            </tr>
                            <tr v-if="plantDetails().storage_normal_butane">
                              <td><b>Normal Butane</b></td>
                              <td>
                                <b>{{
                                  formatNumber(
                                    plantDetails().storage_normal_butane
                                  )
                                }}</b>
                              </td>
                            </tr>
                            <tr v-if="plantDetails().storage_mixed_butanes">
                              <td><b>Mixed Butanes</b></td>
                              <td>
                                {{
                                  formatNumber(
                                    plantDetails().storage_mixed_butanes
                                  )
                                }}
                              </td>
                            </tr>
                            <tr v-if="plantDetails().storage_natural_gasoline">
                              <td><b>Natural Gasoline</b></td>
                              <td>
                                {{
                                  formatNumber(
                                    plantDetails().storage_natural_gasoline
                                  )
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      <b>Ownership</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ex-panel">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Owner
                              </th>
                              <th class="text-left">
                                Share
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            v-if="
                              plantDetails() &&
                                plantDetails().plants_owners &&
                                plantDetails().plants_owners.length > 0
                            "
                          >
                            <tr
                              v-for="(owner, i) in plantDetails().plants_owners"
                              :key="`${owner.organization_name}-${i}`"
                            >
                              <td v-if="owner.organization_name">
                                <b>{{ owner["organization_name"] }}</b>
                              </td>
                              <td v-if="owner.percentage">
                                {{ owner["percentage"] }} %
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="plantDetails().modified">
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      <b>More</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr v-if="plantDetails().modified">
                              <td><b>Last Modified Date:</b></td>
                              <td>{{ plantDetails().modified }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      <b>Contact</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="
                        plantDetails() &&
                          plantDetails().plants_owners &&
                          plantDetails().plants_owners.length > 0
                      "
                    >
                      <b>{{ plantDetails().plant_name }}</b>
                      <br />
                      {{ plantDetails().address }}, {{ plantDetails().county }},
                      {{ plantDetails().state }},
                      {{ plantDetails().postal_code }}
                      <br />
                      tel. {{ plantDetails().phone }}
                      <br />
                      fax. {{ plantDetails().fax }}
                      <br />
                      <br />
                      <v-divider></v-divider>
                      <span
                        v-if="plantDetails() && plantDetails().plants_owners"
                      >
                        <b>{{
                          plantDetails().plants_owners[0].organization_name
                        }}</b>
                        <br />
                        {{ plantDetails().plants_owners[0].address }},
                        {{ plantDetails().plants_owners[0].county }},
                        {{ plantDetails().plants_owners[0].state }},
                        {{ plantDetails().plants_owners[0].postal_code }}
                        <br />
                        tel. {{ plantDetails().plants_owners[0].phone }}
                        <br />
                        fax. {{ plantDetails().plants_owners[0].fax }}
                        <br />
                        <br />
                      </span>
                      <v-divider></v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card class="card">
            <v-container class="filter-container">
              <br />
              <div class="filter-heading-outer">
                <div class="filter-heading-inner">
                  <div class="filter-heading">Apply Filters</div>
                  <DownloadButton :pbfIds="getFilteredPlantIds" />
                </div>
                <v-btn @click="clearFilters" color="primary" text
                  >CLEAR FILTERS</v-btn
                >
              </div>
              <v-autocomplete
                v-if="filterOptions().basins"
                key="basinFilter"
                v-model="filters.basinSelected"
                :items="filterOptions().basins"
                @input="
                  filterPlantsArrayInput('basin', '==', filters.basinSelected)
                "
                outlined
                dense
                chips
                small-chips
                label="Basin"
                multiple
              ></v-autocomplete>
              <v-autocomplete
                v-if="filterOptions().states"
                key="stateFilter"
                v-model="filters.stateSelected"
                :items="filterOptions().states"
                @input="
                  filterPlantsArrayInput('state', '==', filters.stateSelected)
                "
                outlined
                dense
                chips
                small-chips
                label="State"
                multiple
              ></v-autocomplete>
              <v-autocomplete
                v-if="filterOptions().counties"
                key="countyFilter"
                v-model="filters.countySelected"
                :items="filterOptions().counties"
                @input="
                  filterPlantsArrayInput('county', '==', filters.countySelected)
                "
                outlined
                dense
                chips
                small-chips
                label="County"
                multiple
              ></v-autocomplete>
              <v-autocomplete
                v-if="filterOptions().plant_name"
                key="plantNameFilter"
                v-model="filters.plantName"
                :items="filterOptions().plant_name"
                @input="filterPlantsArrayInput('name', '==', filters.plantName)"
                outlined
                dense
                chips
                small-chips
                label="Plant Name"
                multiple
              ></v-autocomplete>
              <v-autocomplete
                v-if="filterOptions().organizations"
                key="organizationNameFilter"
                v-model="filters.organizationName"
                :items="filterOptions().organizations"
                @input="
                  filterPlantsArrayInput(
                    'organization',
                    '==',
                    filters.organizationName
                  )
                "
                outlined
                dense
                chips
                small-chips
                label="Organization Name"
                multiple
              ></v-autocomplete>
              <v-expansion-panels multiple flat>
                <FilterComponent
                  attribute="capacity"
                  :filterMax="filters.maxCapacity"
                  :filterMin="filters.minCapacity"
                  :filterPlants="filterPlants"
                  title="Capacity (MMcfd)"
                />
                <FilterComponent
                  attribute="gas"
                  :filterMax="filters.maxGas"
                  :filterMin="filters.minGas"
                  :filterPlants="filterPlants"
                  title="Inlet Natural Gas (MMcfd)"
                />
                <FilterComponent
                  attribute="mix"
                  :filterMax="filters.maxMix"
                  :filterMin="filters.minMix"
                  :filterPlants="filterPlants"
                  title="Natural Gas Liquids (bbls)"
                />
                <FilterComponent
                  attribute="eth"
                  :filterMax="filters.maxETH"
                  :filterMin="filters.minETH"
                  :filterPlants="filterPlants"
                  title="Ethane (bbls)"
                />
                <FilterComponent
                  attribute="cond"
                  :filterMax="filters.maxCOND"
                  :filterMin="filters.minCOND"
                  :filterPlants="filterPlants"
                  title="Condensates (bbls)"
                />
                <FilterComponent
                  attribute="prop"
                  :filterMax="filters.maxPROP"
                  :filterMin="filters.minPROP"
                  :filterPlants="filterPlants"
                  title="Propane (bbls)"
                />
                <FilterComponent
                  attribute="nbut"
                  :filterMax="filters.maxNBUT"
                  :filterMin="filters.minNBUT"
                  :filterPlants="filterPlants"
                  title="Normal Butanes (bbls)"
                />
                <FilterComponent
                  attribute="pent"
                  :filterMax="filters.maxPENT"
                  :filterMin="filters.minPENT"
                  :filterPlants="filterPlants"
                  title="Pentane (bbls)"
                />
                <FilterComponent
                  attribute="ibut"
                  :filterMax="filters.maxIBUT"
                  :filterMin="filters.minIBUT"
                  :filterPlants="filterPlants"
                  title="Isobutanes (bbls)"
                />
              </v-expansion-panels>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card class="card">
            <v-container
              class="filter-container"
              v-if="
                plantDetails() &&
                  plantDetails().plants_data &&
                  plantDetails().plants_data.length > 0
              "
            >
              <v-divider></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">
                    {{ plantDetails().plant_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ plantDetails().city ? plantDetails().city + ", " : ""
                    }}{{
                      plantDetails().county
                        ? plantDetails().county + " County, "
                        : ""
                    }}{{ plantDetails().state }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <br />
              <div class="charts-outer">
                <div
                  v-if="buildChartData('gas').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="gas-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('gas')"
                  />
                </div>
                <div
                  v-if="buildChartData('mix').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="mix-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('mix')"
                  />
                </div>
                <div
                  v-if="buildChartData('cond').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="cond-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('cond')"
                  />
                </div>
                <div
                  v-if="buildChartData('eth').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="eth-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('eth')"
                  />
                </div>
                <div
                  v-if="buildChartData('nbut').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="nbut-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('nbut')"
                  />
                </div>
                <div
                  v-if="buildChartData('ibut').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="ibut-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('ibut')"
                  />
                </div>
                <div
                  v-if="buildChartData('pent').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="pent-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('pent')"
                  />
                </div>
                <div
                  v-if="buildChartData('prop').labels.length > 0"
                  class="chart-outer"
                >
                  <Chart
                    key="prop-chart"
                    :width="getPanelWidth()"
                    :height="400"
                    :chartData="buildChartData('prop')"
                  />
                </div>
              </div>
             </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import FilterComponent from "./FilterComponent";
import DownloadButton from "./DownloadButton";
import Chart from "./Chart";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Auth } from "aws-amplify";

export default {
  name: "SidePanel",
  data() {
    return {
      logo: require("../assets/LPGAlmanac_logo_black.svg"),
      expanded: [0],
      tab: "Plant Details",
      tabItems: ["About", "Plant Details", "Filter Plants", "Charts"],
      contactInfo: [
        "PlantAddress",
        "PlantorganizationName",
        "PlantShortState",
        "PlantPostalCode",
        "PlantPhone",
      ],
      filters: {
        organizationName: [],
        plantName: [],
        typeSelected: [],
        minCapacity: "",
        maxCapacity: "",
        maxGas: "",
        minGas: "",
        maxCOND: "",
        minCOND: "",
        maxPROP: "",
        minPROP: "",
        maxETH: "",
        minETH: "",
        maxMix: "",
        minMix: "",
        maxNBUT: "",
        minNBUT: "",
        maxPENT: "",
        minPENT: "",
      },
      productionMetrics: [
        "Volume",
        "Capacity",
        "Gas14",
        "Mix14",
        "ETH14",
        "PROP14",
        "IBUT14",
        "NBUT14",
        "PENT14",
        "COND14",
        "Gas15",
        "Mix15",
        "ETH15",
        "PROP15",
        "IBUT15",
        "NBUT16",
        "PENT16",
        "COND16",
        "Gas",
        "Mix",
        "ETH",
        "PROP",
        "IBUT",
        "NBUT",
        "PENT",
        "COND",
      ],
    };
  },
  components: { Chart, DownloadButton, FilterComponent },
  props: [
    "filterPlantsArrayInput",
    "showPanel",
    "data",
    "filterPlants",
    "resetFilter",
    "map",
  ],
  methods: {
    getPanelWidth() {
      return this.$refs.sidepanel.clientWidth - 40;
    },
    getFilteredPlantIds() {
      return this.map
        .queryRenderedFeatures({ layers: ["plants"] })
        .map((f) => f.id);
    },
    setTableHeaders() {
      let headers = Array.from(
        new Set(this.plantDetails().plants_data.map((d) => d.attribute_name))
      );
      if (headers.includes("Inlet Natural Gas")) {
        let index = headers.indexOf("Inlet Natural Gas");
        headers.splice(index, 1);
        headers.unshift("Inlet Natural Gas");
      }
      let tableHeaders = headers.map((h) => {
        let unit = this.plantDetails().plants_data.find(
          (p) => p.attribute_name === h
        ).unit_code;
        return {
          text: `${h} (${unit})`,
          align: "start",
          sortable: false,
          value: h,
        };
      });
      tableHeaders.unshift({
        text: "Date",
        align: "start",
        sortable: false,
        value: "Date",
      });
      return tableHeaders;
    },
    formatNumber(num) {
      if (num && Number(num)) {
        return Number(num)
          .toFixed(0)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return "";
      }
    },
    hasStorageData() {
      let some = [
        "storage_ngl_mix",
        "storage_ethane",
        "storage_propane",
        "storage_iso_butane",
        "storage_normal_butane",
        "storage_mixed_butanes",
        "storage_natural_gasoline",
      ].some((s) => this.plantDetails()[s]);
      return some;
    },
    setTableItems() {
      let tableItems = [];
      this.plantDetails().plants_data.forEach((d) => {
        let existingRow = tableItems.findIndex(
          (row) => row.Date === d.created.split("-")[0]
        );
        if (existingRow >= 0) {
          tableItems[existingRow][d.attribute_name] = this.formatNumber(
            d.numeric_value
          );
        } else {
          let newRow = { Date: d.created.split("-")[0] };
          newRow[d.attribute_name] = this.formatNumber(d.numeric_value);
          tableItems.push(newRow);
        }
      });
      tableItems = tableItems.sort((a, b) => b.Date - a.Date);
      return tableItems;
    },
    async logout() {
      this.map.removeLayer("plants");
      this.map.removeLayer("basins");
      this.map.removeSource("plants");
      this.map.removeSource("basins");
      await Auth.signOut();
      this.setIdToken(null);
      this.setAccessToken(null);
    },
    checkUser() {
      return this.getIdToken();
    },
    login() {
      this.setLoggingIn(true);
    },
    buildChartData(attribute_slug) {
      let chartData = { labels: [], datasets: [{ data: [] }] };
      let colours = {
        gas: "#87CEEB",
        mix: "#4682B4",
        cond: "#4169E1",
        prop: "#000080",
        nbut: "#87CEEB",
        eth: "#483D8B",
        pent: "#8A2BE2",
        ibut: "#1b9ea4",
      };
      if (this.plantDetails()) {
        this.plantDetails()
          .plants_data.filter((d) => d.attribute_slug === attribute_slug)
          .forEach((p) => {
            chartData.datasets[0].label = `${p.attribute_name}, ${p.unit_code}`;
            chartData.datasets[0].borderColor = colours[attribute_slug];
            chartData.datasets[0].fill = false;
            chartData.datasets[0].data.push(p.numeric_value);
            chartData.labels.push(p.created);
            return p;
          });
        return chartData;
      } else {
        return null;
      }
    },
    clearFilters() {
      Object.keys(this.filters).forEach((f) => {
        if (Array.isArray(this.filters[f])) {
          this.filters[f].splice(0, this.filters[f].length);
        } else {
          this.filters[f] = "";
        }
      });
      if (
        Object.keys(this.filters).every(
          (f) => !this.filters[f] || this.filters[f].length < 1
        )
      ) {
        this.resetFilter();
      }
    },
    ...mapActions(["getPlantDetails", "getFilterOptions"]),
    ...mapMutations(["setLoggingIn", "setIdToken", "setAccessToken"]),
    ...mapGetters({
      plantDetails: "plantDetails",
      filterOptions: "filterOptions",
      getLoggingIn: "getLoggingIn",
      getIdToken: "getIdToken",
      getAccessToken: "getAccessToken",
      getFilteredPlantsForDownload: "getFilteredPlantsForDownload",
    }),
  },
};
</script>

<style scoped>
img {
  float: left;
  margin-left: -30px;
  margin-top: -25px;
}
.overline {
  color: gray;
  margin-left: 5px;
}
.heading-login {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.login-btn-outer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 4px;
}
.login-icon {
  margin-right: 10px;
  font-size: 20px !important;
}
.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.login-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: white;
}
.v-expansion-panel-header {
  padding: 0 !important;
}
p {
  color: grey;
  padding: 0;
}
.range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-field {
  padding: 10px;
}
.filter-container {
  padding: 30px;
}
.filter-heading-outer {
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.filter-heading-inner {
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
  padding: 0;
  margin: 0;
}
.filter-heading {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}
.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icon {
  padding-right: 10px;
}
.icons-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.link:link {
  text-decoration: none;
}
.plant-name {
  margin-right: 10px;
}
.plant-name-outer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.chart-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
}
.charts-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

</style>
