<template>
  <v-app>
    <v-main>
      <div>
        <div v-if="getLoggingIn">
          <amplify-authenticator>
            <amplify-sign-in
              slot="sign-in"
              :hide-sign-up="true"
              username-alias="email"
            >
            </amplify-sign-in>
            <router-view />
          </amplify-authenticator>
        </div>
        <div v-if="!getLoggingIn">
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { mapMutations, mapGetters } from "vuex";
import { Auth } from "aws-amplify";

export default {
  name: "App",
  computed: {
    ...mapGetters(["getLoggingIn"]),
  },
  beforeCreate() {
    Auth.currentSession()
      .then((data) => {
        this.setIdToken(data.idToken.jwtToken);
        this.setAccessToken(data.accessToken.jwtToken);
        this.getFilterOptions({ token: data.idToken.jwtToken });
      })
      .catch((err) => {
        console.error("NO USER", err);
      });

    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if (authData) {
        this.setAccessToken(authData.signInUserSession.accessToken.jwtToken);
        this.setIdToken(authData.signInUserSession.idToken.jwtToken);
        this.setLoggingIn(false);
        this.getFilterOptions({
          token: authData.signInUserSession.idToken.jwtToken,
        });
      }
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
  methods: {
    ...mapMutations(["setLoggingIn", "setAccessToken", "setIdToken"]),
  },
};
</script>
<style>
.mapboxgl-ctrl-top-right {
  margin-right: 17px;
}
</style>
