<template>
  <v-expansion-panel>
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      {{title}}
      </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="range">
            <v-text-field
              class="text-field"
              v-model="filterMin"
              :label="`Min ${title}`"
              required
              @input="filterPlants(attribute, '>=', filterMin)"
            ></v-text-field>
            <v-text-field
              class="text-field"
              v-model="filterMax"
              :label="`Max ${title}`"
              required
              @input="filterPlants(attribute, '<=', filterMax)"
            ></v-text-field>
        </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  export default {
    name: 'filtercomponent',
    data () {
      return {
        filterMax: '',
        filterMin: ''
     }
   },
   props: ['attribute', 'filterPlants', 'title']
}
</script>

<style scoped>
.v-tabs-items {
  height: 100% !important;
}
.panelShow {
  height: 100vh;
  left: 0;
  width: 30vw;
  position: fixed;
  z-index: 8000;
  background: white;
  overflow-y: scroll;
}
.panelHide {
  left: 0;
  height: 100vh;
  width: 1vw;
  position: fixed;
  z-index: 8000;
  background: white;
  overflow-y: scroll;
}
.v-expansion-panel-header {
  padding: 0 !important;
}
p {
  color: grey;
  padding: 0;
}
.range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-field {
  padding: 10px;
}
.filter-container {
  padding: 30px;
}
.filter-heading-outer {
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.filter-heading {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}

</style>
