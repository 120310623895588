<template>
  <json-excel
    :before-generate="startDownload"
    :before-finish="finishDownload"
    :fields="json_fields"
    :fetch="getPlantData"
    title="Plant Details"
    name="PlantDetails.csv"
    :escapeCsv="false"
    type="csv"
  >
    <v-tooltip top v-if="!generating">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="icon" v-on="on" v-bind="attrs" medium color="green"
          >mdi-download</v-icon
        >
      </template>
      <span>Download Plant Data</span>
    </v-tooltip>
    <div v-if="generating" class="generating-icon">
      <Stretch size="20px"></Stretch>
    </div>
  </json-excel>
</template>

<script>
import JsonExcel from "vue-json-excel";
import { Stretch } from "vue-loading-spinner";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DownloadButton",
  components: { JsonExcel, Stretch },
  props: ["selectedFeature", "pbfIds"],
  data() {
    return {
      generating: false,
      json_fields: {},
      json_data: [],
      excludedFields: ["plant_id", "slug"],
    };
  },
  methods: {
    finishDownload() {
      this.generating = false;
    },
    startDownload() {
      this.generating = true;
    },
    async getPlantData() {
      let pbfIds = this.selectedFeature || this.pbfIds();
      try {
        let req = await fetch(`${process.env.VUE_APP_API_URL}/plantdetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getIdToken()}`,
          },
          body: JSON.stringify({ pbf_id: pbfIds, toDownload: true }),
        });

        let res = await req.json();

        console.log("RES", res);

        if (res?.data) {
          console.log("resdata", res.data);
          this.json_data = res.data.json_data;
          this.json_fields = res.data.json_fields;
          return this.json_data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    ...mapActions(["getPlantDetails"]),
    ...mapGetters({
      getFilteredPlantsForDownload: "getFilteredPlantsForDownload",
      getIdToken: "getIdToken",
    }),
  },
};
</script>
<style scoped>
.generating-icon {
  margin-bottom: -8px;
}
</style>
