import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cognitoInfo: {},
    loggedIn: false,
    loadingState: true,
    errorLoadingState: false,
    public: "",
    plantDetails: "",
    filterOptions: "",
    user: "",
    loggingIn: false,
    idToken: "",
    accessToken: "",
    filteredPlantsForDownload: "",
    pbfVersion: "",
  },

  getters: {
    plantDetails: (state) => {
      return state.plantDetails;
    },
    pbfVersion: (state) => {
      return state.pbfVersion;
    },
    filterOptions: (state) => {
      return state.filterOptions;
    },
    getLoggingIn: (state) => {
      return state.loggingIn;
    },
    getIdToken: (state) => {
      return state.idToken;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
    getFilteredPlantsForDownload: (state) => {
      return state.filteredPlantsForDownload;
    },
  },

  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setIdToken(state, payload) {
      state.idToken = payload;
    },
    setPublic(state, payload) {
      state.public = payload;
    },
    setLoggingIn(state, payload) {
      state.loggingIn = payload;
    },
    setPlantDetails(state, payload) {
      state.plantDetails = { ...payload };
    },
    setFilteredPlantsForDownload(state, payload) {
      state.filteredPlantsForDownload = { ...payload };
    },
    setFilterOptions(state, payload) {
      state.filterOptions = { ...payload };
    },
    setLoggedIn(state, payload) {
      state.loggedIn = payload;
    },
    setLoadingState(state, payload) {
      state.loadingState = payload;
    },
    setErrorLoadingState(state, payload) {
      state.errorLoadingState = payload;
    },
    setPBFVersion(state, payload) {
      state.pbfVersion = payload;
    },
  },

  actions: {
    async getPlantDetails({ commit }, payload) {
      let { token, pbf_id, multiple } = payload;

      try {
        let req = await fetch(`${process.env.VUE_APP_API_URL}/plantdetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ pbf_id, toDownload: multiple }),
        });

        console.log("req", req);

        let res = await req.json();

        console.log("RES", res);

        if (res.data) {
          if (multiple) {
            commit("setFilteredPlantsForDownload", res.data);
          } else {
            commit("setPlantDetails", res.data[0]);
          }
        }

        return res.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getPBFVersion({ commit }, payload) {
      try {
        let { token } = payload;

        let req = await fetch(`${process.env.VUE_APP_API_URL}/schemaversion`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const res = await req.json();

        if (res.data) {
          commit("setPBFVersion", res.data);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getFilterOptions({ commit }, payload) {
      try {
        console.log("getFilterOptions");
        let { token } = payload;
        const req = await fetch(
          `${process.env.VUE_APP_API_URL}/filteroptions`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const res = await req.json();

        if (res.data && res.data) {
          commit("setFilterOptions", res.data);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
});
