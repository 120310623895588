<template>
  <div class="map-outer">
    <SidePanel
      :showPanel="showPanel"
      :selectedFeature="selectedFeature"
      :data="data"
      :filterPlants="filterPlants"
      :resetFilter="resetFilter"
      :filterPlantsArrayInput="filterPlantsArrayInput"
      :map="map"
    />
    <div id="map"></div>
    <MapLegend />
    <div id="menu">
      <input
        id="basins"
        type="checkbox"
        @click="toggleBasins()"
        v-model="checked"
      />
      <label for="basins" class="label">Basins</label>
      <input
        id="light-v10"
        v-model="mapStyle"
        type="radio"
        name="rtoggle"
        value="light-v10"
        @input="toggleMap('light-v10')"
      />
      <label for="light-v10" class="label">Light</label>
      <input
        id="streets-v11"
        v-model="mapStyle"
        type="radio"
        name="rtoggle"
        value="streets-v11"
        @input="toggleMap('streets-v11')"
      />
      <label for="streets-v11" class="label">Streets</label>
      <input
        id="satellite-streets-v11"
        v-model="mapStyle"
        type="radio"
        name="rtoggle"
        value="satellite-streets-v11"
        @input="toggleMap('satellite-streets-v11')"
      />
      <label for="satellite-streets-11">Satellite</label>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import SidePanel from "./SidePanel";
import MapLegend from "./MapLegend";
import { Auth } from "aws-amplify";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "mapbox",
  data() {
    return {
      checked: true,
      map: "",
      showPanel: true,
      selectedFeature: "",
      data: "",
      activeFilters: [],
      mapStyle: "streets-v11",
      tokenRefreshTimer: "",
    };
  },
  components: { MapLegend, SidePanel },
  computed: {
    pbfV() {
      return this.$store.getters.pbfVersion;
    },
  },
  watch: {
    pbfV(newVal, oldVal) {
      console.log("WATCH", newVal, oldVal);
      if (newVal !== oldVal) {
        console.log(`pbfVersion changed from ${oldVal} to ${newVal}`);
        if (this.getAccessToken() && this.pbfV) {
          this.addBasins();
          this.addPoints();
        }
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic3VscGV0cm8iLCJhIjoiY2tsazAzZXczNTJzdTJwbnJkZThjd2JnMSJ9.urmqpFM9dBwtOqd2nBAkEQ";
    this.map = new mapboxgl.Map({
      container: "map", // container id
      style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
      center: [-110.9018, 40.9686], // starting position [lng, lat]
      zoom: 4,
      transformRequest: (url) => {
        if (url.startsWith("https://dj6cqaw6lnav0.cloudfront.net")) {
          if (this.tokenRefreshTimer) {
            clearTimeout(this.tokenRefreshTimer);
          }
          this.tokenRegfreshTimer = setTimeout(() => {
            Auth.currentSession()
              .then((data) => {
                this.setIdToken(data.idToken.jwtToken);
                this.setAccessToken(data.accessToken.jwtToken);
              })
              .catch((err) => {
                console.log("map 104", err);
              });
          }, 18000);

          let opts = {
            url: url,
            headers: {
              Accept: "application/octet-stream",
            },
            credentials: "include",
          };
          let token = this.getAccessToken();
          if (token) {
            opts.headers.Authorization = `Bearer ${token}`;
          }
          return opts;
        }
      },
    });
    this.map.on("style.load", () => {
      console.log("style load 1", this.getIdToken());
      if (this.pbfV) {
        this.addBasins();
        this.addPoints();
        this.getFilterOptions({ token: this.getIdToken() });
      } else if (this.getIdToken()) {
        this.getPBFVersion({ token: this.getIdToken() });
        this.getFilterOptions({ token: this.getIdToken() });
      }
    });
    this.map.on(
      "click",
      (e) => {
        e.stopPropagation();
      },
      true
    );
  },
  methods: {
    toggleMap(style) {
      this.map.setStyle("mapbox://styles/mapbox/" + style);
    },
    toggleBasins() {
      if (this.checked) {
        this.map.setLayoutProperty("basins", "visibility", "none");
      } else {
        this.map.setLayoutProperty("basins", "visibility", "visible");
      }
    },
    addPoints() {
      let url = `https://dj6cqaw6lnav0.cloudfront.net/${this.pbfVersion()}/plants/{z}/{x}/{y}.pbf`;
      this.map.addSource("plants", {
        type: "vector",
        tiles: [url],
        maxZoom: 14,
      });

      this.map.addLayer({
        id: "plants",
        type: "circle",
        source: "plants",
        "source-layer": "plants",
        paint: {
          "circle-color": [
            "match",
            ["get", "main_type"],
            "Gas Processing",
            "#0A82AD",
            "Gas Processing + Frac.",
            "#dd9e21",
            "Fractionation",
            "#389200",
            "Fractionation/Terminal",
            "#e55e5e",
            "Fractionation/Terminal/Storage",
            "#01579B",
            "Storage",
            "#9E20AC",
            "Terminal",
            "#E51405",
            "Terminal/Storage",
            "#3bb2d0",
            "Refinery",
            "#DC418F",
            /* other */ "#ccc",
          ],
          "circle-radius": [
            "interpolate",
            ["exponential", 1.5],
            ["zoom"],
            0,
            6,
            10,
            [
              "case",
              ["all", ["!", ["has", "capacity"]]],
              5,
              ["all", ["<", ["get", "capacity"], 50]],
              5,
              ["all", [">=", ["get", "capacity"], 50]],
              ["*", 0.1, ["get", "capacity"]],
              6,
            ],
            15,
            [
              "case",
              ["all", ["!", ["has", "capacity"]]],
              5,
              [
                "all",
                [">", ["get", "capacity"], 0],
                ["<=", ["get", "capacity"], 10],
              ],
              ["/", 10, ["get", "capacity"]],
              [
                "all",
                [">", ["get", "capacity"], 10],
                ["<=", ["get", "capacity"], 1000],
              ],
              ["/", 10, ["get", "capacity"]],
              6,
            ],
          ],
          "circle-stroke-color": "white",
          "circle-stroke-width": 1,
        },
      });
      this.addPopup("plants");
      this.map.on("click", "plants", this.clickPoint);
      // this.map.on('zoomstart', () => console.log('ZOOOM', this.map.getZoom()))
    },
    addBasins() {
      let url = `https://dj6cqaw6lnav0.cloudfront.net/${this.pbfVersion()}/basins/{z}/{x}/{y}.pbf`;
      this.map.addSource("basins", {
        type: "vector",
        tiles: [url],
        maxZoom: 12,
      });

      this.map.addLayer({
        id: "basins",
        type: "fill",
        source: "basins",
        "source-layer": "basins",
        paint: {
          "fill-color": "#D5C1AF",
          "fill-outline-color": "#C7AC92",
          "fill-opacity": 0.5,
        },
      });
      this.addBasinsPopup("basins");
    },
    filterPlants(property, operator, value) {
      let val = Number(value) ? Number(value) : value;
      let existsIndex = this.activeFilters.findIndex(
        (f) => f[1] === property && f[0] === operator
      );
      if (existsIndex > -1) {
        this.activeFilters.splice(existsIndex, 1);
      }
      if (val && val !== undefined && Number(val)) {
        this.activeFilters.push([operator, property, val]);
      }

      let compoundFilter = ["all", ...this.activeFilters];
      this.map.setFilter("plants", compoundFilter);
    },
    filterPlantsArrayInput(property, operator, valueArray) {
      let existsIndex = this.activeFilters.findIndex(
        (f) => f[0] === "in" && f[1] === property
      );
      if (existsIndex > -1) {
        this.activeFilters.splice(existsIndex, 1);
      }
      let compoundFilter;
      if (valueArray.length > 0) {
        let expression = ["in", property, ...valueArray];
        this.activeFilters.push(expression);
      }

      compoundFilter = ["all", ...this.activeFilters];

      if (compoundFilter.length <= 1) {
        compoundFilter = null;
      }

      this.map.setFilter("plants", compoundFilter);
    },
    resetFilter() {
      this.map.setFilter("plants", null);
      this.activeFilters.splice(0, this.activeFilters.length);
    },
    clickPoint(e) {
      // Sanity check.
      if (e.features && e.features.length === 0) {
        console.error("Point clicked but no features found.", e);
        return;
      }
      if (this.tokenRefreshTimer) {
        clearTimeout(this.tokenRefreshTimer);
      }
      this.tokenRefreshTimer = setTimeout(() => {
        Auth.currentSession()
          .then((data) => {
            this.setIdToken(data.idToken.jwtToken);
            this.setAccessToken(data.accessToken.jwtToken);
          })
          .catch((err) => {
            console.log("map 327", err);
          });
      }, 18000);

      this.getPlantDetails({
        token: this.getIdToken(),
        pbf_id: [e.features[0].id],
        multiple: false,
      });

      if (window.innerWidth > 767) {
        this.showPanel = true;
      }
    },
    addPopup(layer) {
      this.popup = new mapboxgl.Popup({
        className: "popup",
        closeButton: false,
        closeOnClick: false,
      });
      this.map.on("mousemove", layer, (e) => {
        if (
          e.features[0].properties === null ||
          e.features[0].properties === undefined
        ) {
          return;
        }
        let props = e.features[0].properties;
        let types = "";
        if (props.types && props.types.length > 0) {
          JSON.parse(props.types).forEach((t, i) => {
            if (i === 0) {
              types += t;
            } else {
              types += `, ${t}`;
            }
          });
        }
        let contentJoined = "";
        contentJoined += `<div><b>${props.name}</b></div><div>${
          props.county ? props.county + " County, " : ""
        }${props.state}</div><div>${types}</div><div>Design Capacity: ${
          props.capacity ? `${props.capacity} MMcfd` : "NA"
        }</div>`;
        let bubble = `<div class="bubble">${contentJoined.toString()}</div>`;
        this.popup
          .setLngLat(e.lngLat)
          .setHTML(bubble)
          .addTo(this.map);
      });
      this.map.on("mouseleave", layer, () => this.popup.remove());
    },
    addBasinsPopup(layer) {
      this.popup = new mapboxgl.Popup({
        className: "popup",
        closeButton: false,
        closeOnClick: false,
      });
      this.map.on("mousemove", layer, (e) => {
        if (
          e.features[0].properties === null ||
          e.features[0].properties === undefined
        ) {
          return;
        }
        let props = e.features[0].properties.name.toLowerCase();
        let bubble = `<div class="bubble">${props.charAt(0).toUpperCase() +
          props.slice(1)} Basin</div>`;
        this.popup
          .setLngLat(e.lngLat)
          .setHTML(bubble)
          .addTo(this.map);
      });
      this.map.on("mouseleave", layer, () => this.popup.remove());
    },
    ...mapMutations(["setLoggingIn", "setIdToken", "setAccessToken"]),
    ...mapActions(["getPlantDetails", "getFilterOptions", "getPBFVersion"]),
    ...mapGetters({
      pbfVersion: "pbfVersion",
      getLoggingIn: "getLoggingIn",
      getIdToken: "getIdToken",
      getAccessToken: "getAccessToken",
    }),
  },
};
</script>

<style>
label {
  margin-right: 10px;
}
input {
  margin-right: 10px;
}
.panelShow {
  height: 100vh;
  left: 0;
  width: 37vw;
  position: fixed;
  z-index: 8000;
  background: white;
  overflow-y: scroll;
}
.panelHide {
  left: 0;
  height: 100vh;
  width: 1vw;
  position: fixed;
  z-index: 8000;
  background: white;
  overflow-y: scroll;
}
.map-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: Capacity;
}
#map {
  width: 100%;
  height: 100%;
  overflow: Capacity;
}
#menu {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 35px;
  border-radius: 3px;
  border: 1px solid rgba(240, 240, 240, 0.8);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  right: 120px;
  width: 360px;
  background: #fff;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  z-index: 8000;
}
</style>
